module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.2,"once":true,"rootMargin":"0% 100%"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#001D6F","theme_color":"#252AD4","display":"minimal-ui","icon":"/opt/build/repo/@solublestudio/idc-design-system/src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"df9de4089eaa704e07ecdfd0b9004c7e"},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"quality":90,"withWebp":true,"srcSetBreakpoints":[576],"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../@solublestudio/gatsby-theme-soluble-source/gatsby-browser.js'),
      options: {"plugins":[],"primaryLanguage":"en","language":{"en":""},"pathsByLanguage":{},"datocms":{"apiToken":"1b59f8f9c0a2eb719a24314c35cd4d","modelPages":["Page"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
